@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  h1 {
    @apply text-felix font-noe;
    font-size: 40px;
  }

  h2 {
    @apply text-felix font-bold;
    font-size: 32px;
  }

  h3 {
    @apply text-felix font-light;
    font-size: 30px;
  }

  h4 {
    @apply text-felix font-bold;
    font-size: 24px;
  }

  .body1 {
    @apply text-felix;
    font-size: 24px;
  }

  .body2 {
    @apply text-felix;
    font-size: 20px;
  }

  .smallcaps {
    @apply text-felix;
    font-size: 18px;
    text-transform: uppercase;
  }

  .smallcaps {
    @apply text-felix;
    font-size: 14px;
    text-transform: uppercase;
  }
}
